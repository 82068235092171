<template>
    <v-card outlined elevation="1">
        <v-card-text class="d-flex align-center pa-3" style="width: 100%">
            <div class="d-flex align-start flex-grow-1">
                <v-chip 
                    :color="is_anulado ? 'secondary' : 'primary'" 
                    large 
                    label 
                    class="font-weight-bold d-flex justify-center align-self-stretch" 
                    style="min-width: 50px"
                    >
                    {{ juegoResultado.resultado }}
                </v-chip>
                <v-divider vertical class="mx-3"/>
                <div>
                    <div class="d-flex mb-2">
                        <v-chip 
                            class="text-uppercase font-weight-bold" 
                            label 
                            small 
                            color="primary"
                            >
                            {{ juegoResultado.juego.name }} - {{ juegoResultado.juego_sorteo.horario }}
                        </v-chip>
                        <v-divider vertical class="mx-3"/>
                        <v-chip class="text-uppercase" label small outlined>REGISTRADO: {{ juegoResultado.created_at | datetime }}</v-chip>
                        <v-divider vertical class="mx-3" v-if="juegoResultado.provider_juego_resultado"/>
                        <v-chip
                            v-if="juegoResultado.provider_juego_resultado" 
                            class="text-uppercase font-weight-bold" 
                            label 
                            small
                            :color="$helper.colorBaseOnSeed(juegoResultado.provider_juego_resultado.provider_juego.provider.slug)" 
                            :dark="!$helper.isColorBright($helper.colorBaseOnSeed(juegoResultado.provider_juego_resultado.provider_juego.provider.slug))"
                            outlined
                            >
                            PROVEEDOR: {{ juegoResultado.provider_juego_resultado.provider_juego.provider.slug }}
                        </v-chip>
                    </div>
                    <div class="d-flex">
                        <v-chip small label outlined class="font-weight-bold mr-2 px-8" color="error" v-if="is_anulado">
                            ANULADO EL: {{ juegoResultado.is_anulado_at | bdatetime }}
                        </v-chip>
                        <v-chip small label outlined class="font-weight-bold mr-2">
                            PREMIADOS: {{ juegoResultado.count }}
                            <v-icon class="ml-2" small color="primary">mdi mdi-receipt-text</v-icon>
                        </v-chip>
                        <v-chip small label outlined class="font-weight-bold">
                            PAGADOS: {{ juegoResultado.count_pagado }}
                            <v-icon class="ml-2" small color="accent">mdi mdi-receipt-text</v-icon>
                        </v-chip>
                    </div>
                </div>
            </div>  
            <div class="d-flex">
                <Anular v-if="!is_anulado" @anular="anulado" :juegoResultado="juegoResultado"/>
            </div>
        </v-card-text>
        <v-divider v-if="juegoResultado.juego_resultado_child_list.length"/>
        <v-card-actions v-if="juegoResultado.juego_resultado_child_list.length">
            <div style="width: 100%">
                <div>JUEGOS AFECTADOS POR EL RESULTADO</div>
                <div>
                    <ItemChildren 
                        v-for="(juegoResultadoChild, index) of juegoResultado.juego_resultado_child_list"
                        :key="`resultado-list-list-item-child-${index}-${juegoResultadoChild.id}`"
                        :juegoResultado="juegoResultadoChild"
                        />
                </div>
            </div>
        </v-card-actions>
    </v-card>
</template>
<script>

import Anular from './actions/anular.vue';
import ItemChildren from './item.children.vue';

export default {
    components: {
        ItemChildren,
        Anular,
    },
    props: {
        juegoResultado: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            is_anulado: this.juegoResultado.is_anulado,
        };
    },
    computed: {
    },
    mounted: function () {
    },
    methods: {
        anulado: function () {
            this.is_anulado = true;
        },
    },
    watch: {
    },
}
</script>
<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title>Filtros</v-card-title>
                <v-card-text><TicketSearch :loading="loading" :default="params" @search="params = $event"/></v-card-text>
            </v-card>
            <TicketList :params="params" :loading="loading"/>
        </v-col>
    </v-row>
</v-container>
</template>
    
<script>
import TicketSearch from '@components/entities/ticket/search';
import TicketList from '@components/entities/ticket';

export default {
    components: {
        TicketSearch,
        TicketList,
    },
    data: function () {
        const defaultParams = this.setQuery({
            date: this.$luxon.local().toISODate(),
        });
        return {
            options: {},
            loading: false,

            isModalOpen: false,
            item: null,

            default: defaultParams,
            params: {...defaultParams},
        };
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Admin. de Tickets');
    },
    methods: {
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        },
        setQuery: function (params = {}) {
            const query = {... this.$route.query};

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    const element = query[key];

                    if (key == 'numero') {
                        query[key] = element;
                        continue;
                    }

                    query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                }
            }

            return { ... params, ... query };
        },
    },
}
</script>
<template>
    <v-card outlined style="width: 100%">
        <v-card-text class="d-flex flex-wrap align-center pa-1" style="width: 100%">
            <v-chip class="text-uppercase font-weight-bold mr-2" label small>{{ juegoResultado.juego.name }} - {{ juegoResultado.juego_sorteo.horario }}</v-chip>
            <v-spacer/>
            <v-chip small label outlined class="font-weight-bold mr-2">
                PREMIADOS: {{ juegoResultado.count }}
                <v-icon class="ml-2" small color="primary">mdi mdi-receipt-text</v-icon>
            </v-chip>
            <v-chip small label outlined class="font-weight-bold">
                PAGADOS: {{ juegoResultado.count_pagado }}
                <v-icon class="ml-2" small color="accent">mdi mdi-receipt-text</v-icon>
            </v-chip>
            <v-divider vertical class="mx-3" v-if="is_anulado"/>
            <v-chip small label outlined class="font-weight-bold mr-2 px-8" color="error" v-if="is_anulado">ANULADO EL: {{ juegoResultado.is_anulado_at | bdatetime }}</v-chip>
        </v-card-text>
    </v-card>
</template>
<script>

export default {
    components: {
    },
    props: {
        juegoResultado: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            is_anulado: this.juegoResultado.is_anulado,
        };
    },
    computed: {
    },
    mounted: function () {
    },
    methods: {
        anulado: function () {
            this.is_anulado = true;
        },
    },
    watch: {
    },
}
</script>
<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card class="mb-3">
                    <v-card-title>
                        <div style="flex: 1">
                            Filtros
                        </div>
                        <div>
                            <v-tabs v-model="sistema_type">
                                <v-tab tab-value="sistema">Sistemas</v-tab>
                                <v-tab tab-value="app">Aplicaciones</v-tab>
                            </v-tabs>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <ConsolidadoComercioSearch 
                            context="sistema" 
                            :sistema_type="sistema_type"
                            :default="params" 
                            @search="search($event)"
                            />
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-text>
                        <ConsolidadoComercio context="sistema" :params="params"></ConsolidadoComercio>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import ConsolidadoComercio from '@components/entities/reporte/venta/comercio';
    import ConsolidadoComercioSearch from '@components/entities/reporte/venta/comercio/search';

    export default {
        components: {
            ConsolidadoComercio,
            ConsolidadoComercioSearch
        },
        props: {
        
        },
        data: function() {
            const query = {... this.$route.query};

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    const element = query[key];

                    if (key == 'numero') {
                        query[key] = element;
                        continue;
                    }

                    query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                }
            }

            return {
                params: {
                    daterange: [this.$luxon.now().toISODate(),this.$luxon.now().toISODate()],
                    sistema_type: 'sistema',
                    ... query,
                },
                sistema_type: 'sistema',
            }
        },
        mounted: function () {
            this.$store.dispatch('ui/layout/setTitle', '');
        },
        computed:{
        },
        methods:{
            search: function (params) {
                this.params = params;
            }
        },
        watch: {
            'sistema_type': function () {
                this.params = {
                    ...this.params,
                    sistema_type: this.sistema_type,
                    force_comercio_grouping: this.sistema_type == 'app',
                };
            }
        }
    }
</script>
<template>
    <v-card outlined elevation="4">
        <v-card-text>
            <v-form ref="form" style="width: 100%" v-model="valid">
                <div class="d-flex align-center flex-wrap mb-3">
                    <div class="flex-grow-1 mr-2">
                        <DateInput
                            label="Fecha"
                            @change="changed('fecha', $event)"
                            v-model="fields.fecha"
                            dense
                            :disabled="loading"
                            :loading="loading"
                            />
                    </div>
                    <div class="flex-grow-1 mr-2">
                        <SelectorJuego
                            label="Juego"
                            @change="changed('juego_id', $event)"
                            v-model="fields.juego_id"
                            dense
                            :disabled="loading"
                            :loading="loading"
                            />
                    </div>
                    <div class="flex-grow-1 mr-2">
                        <SelectorJuegoSorteo
                            label="Sorteo"
                            @change="changed('juego_id', $event)"
                            v-model="fields.juego_sorteo_id"
                            :params="{juego_id: fields.juego_id}"
                            :disabled="!fields.juego_id || loading"
                            :loading="loading"
                            :autoselect="false"
                            dense
                            />
                    </div>
                </div>
            </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
            <div class="d-flex align-center" style="width: 100%">
                <v-checkbox 
                    label="ANULADOS"
                    @change="changed('is_anulado', $event)"
                    v-model="fields.is_anulado"
                    :disabled="loading"
                    dense
                    hide-details
                    />
                <v-spacer/>
                <v-btn text class="mr-3" :disabled="loading" @click="reset()" small>Reiniciar Filtros</v-btn>
                <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()" small><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import Search from '@mixins/search';

export default {
    components: {
    },
    props: {
        loading: {},
    },
    mixins: [Search],
    data: function ()  {
        return {
            defaultFields: {
                is_anulado: false,
                fecha: this.$luxon.local().toSQLDate(),

                juego_id: null,
                juego_sorteo_id: null,
            },
        }
    },
    computed: {
       
    },
    created: function () {
    },
    mounted: function () {
    },
    methods: {
    },
    watch: {
    }
}
</script>

<style>

</style>
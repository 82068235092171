<template>
    <v-card flat>
        <v-card-title>
            RESULTADOS
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <Search @search="params = $event" :loading="loading" class="mb-3"/>
            <v-card outlined elevation="4">
                <template v-if="list.length">
                    <v-card-title class="d-flex justify-space-between">
                        <div>
                            {{ list.length }} RESULTADOS PARA {{ groupedList.length }} JUEGOS
                        </div>
                        <div>
                            <v-btn-toggle v-model="grouping" mandatory>
                                <v-btn small value="horario">
                                    HORARIO
                                </v-btn>
                                <v-btn small value="juego">
                                    JUEGO
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-card-title>
                    <v-divider/>
                </template>
                <v-card-text>
                    <template v-if="loading">
                        <v-card outlined>
                            <v-card-text class="text-center">
                                <v-progress-circular indeterminate color="primary"/>
                                <div>Cargando</div>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-else>
                        <template v-if="list.length">
                            <div v-for="(group, indexJuego) of groupedList" :key="`resultado-list-group-index-${indexJuego}`" class="mb-6">
                                <v-banner icon="mdi-cards" single-line class="mb-2 font-weight-bold">
                                    {{ group.name }}
                                </v-banner>
                                <Item 
                                    v-for="(juegoResultado, indexResultado) of group.list" 
                                    :key="`resultado-list-group-item-${group.id}-index-${indexJuego}-list-item-${indexResultado}-${juegoResultado.id}`" 
                                    :juegoResultado="juegoResultado" 
                                    class="mb-6"
                                    />
                            </div>
                        </template>
                        <template v-else>
                            <v-card outlined>
                                <v-card-text class="d-flex justify-center">
                                    No hay resultados para la búsqueda
                                </v-card-text>
                            </v-card>
                        </template>
                    </template>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import ResultadoService from '@/services/operadora/resultados/ResultadoService';

import Search from './search';
import Item from './item';

export default {
    components: {
        Item,
        Search
    },
    data: function () {
        return {
            grouping: 'juego',

            loading: false,
            list: [],
            params: {
                is_anulado: false,
                fecha: this.$luxon.local().toISODate()
            }
        };
    },
    computed: {
        groupedList: function () {
            return this.list.reduce((groupedList, resultado) => {
                const groupIndex = groupedList.findIndex(group => group.id == (this.grouping == 'juego' ? resultado.juego_id : resultado.juego_sorteo.horario));
                if (groupIndex !== -1) {
                    const group = groupedList[groupIndex];
                    group.list.push(resultado);
                } else {
                    const group = {
                        list: [resultado]
                    };

                    if (this.grouping == 'juego') {
                        group['id'] = resultado.juego_id;
                        group['name'] = resultado.juego.name;
                    } else {
                        group['id'] = resultado.juego_sorteo.horario;
                        group['name'] = resultado.juego_sorteo.horario;
                    }

                    groupedList.push(group);
                }
                return groupedList;
            }, []);
        }
    },
    mounted: function () {
        this.fetchList();
    },
    methods: {
        fetchList: function (params = {}) {
            this.loading = true;
            this.list = [];
            ResultadoService.getList({...this.params, ...params})
                .then(({ juegoResultadoList }) => {
                    this.list = juegoResultadoList;
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        params: function () {
            this.fetchList();
        },
    },
}
</script>
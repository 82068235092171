<template>
    <v-card class="mb-3 pa-2 px-5 d-flex align-center" :outlined="ui.isDark"  elevation="1" tile :loading="deleting">
        <div>
            <v-chip large label outlined class="font-weight-bold d-flex justify-center" style="min-width: 50px">
                {{ cupoNumero.numero }}
            </v-chip>
        </div>
        <div class="d-flex ml-4">
            <div>
                <v-chip class="d-flex justify-center" :color="$helper.colorBaseOnSeed(cupoNumero.owner)" :dark="!ui.isDark" label outlined small style="width: 100%;">
                    <template v-if="['operadora', 'comercios', 'sistemas'].includes(cupoNumero.owner)">
                        <b class="text-uppercase">{{ cupoNumero.owner }}</b>
                    </template>
                    <template v-else>
                        <b class="text-uppercase">{{ cupoNumero.owner }}:</b> <span class="ml-1">{{ cupoNumero.owner_related.name }}</span>
                    </template>
                </v-chip>
                <div class="text-overline text-center text-uppercase" style="line-height: 1.25rem;">
                    {{ cupoNumero.juego.name }} - {{ cupoNumero.juego_sorteo.horario }}
                </div>
            </div>
            <div class="ml-4">
                <v-divider vertical/>
            </div>
        </div>
        <div class="ml-4 d-flex align-center">
            <div>
                <v-chip label class="font-weight-bold mr-4 text-uppercase" small>{{ cupoNumero.divisa.name }}</v-chip>
            </div>
            <div v-if="!cupoNumero.is_bloqueado">
                <v-text-field dense outlined class="ma-0" readonly hide-details="" v-model="cupo">
                    <template slot="append">
                        <v-icon v-if="cupoNumero.is_percent">mdi-percent</v-icon>
                        <template v-else>
                            {{ cupoNumero.divisa.code }}
                        </template>
                    </template>
                </v-text-field>
            </div>
        </div>
        <div v-if="cupoNumero.is_bloqueado" class="flex-grow-1 d-flex justify-center text-h6">
            BLOQUEADO
        </div>
        <div class="ml-auto">
            <v-btn outlined small :disabled="deleting" :loading="deleting" @click="deleteCupo">
                <v-icon dark small>
                    mdi-delete
                </v-icon>
                Eliminar
            </v-btn>
        </div>
    </v-card>
</template>
<script>
import CupoNumeroService from '@/services/comercio/CupoNumeroService';

export default {
    props: {
        cupoNumero: {
            required: true
        }
    },
    data: function () {
        return {
            deleting: false,
            cupo: this.cupoNumero.cupo,
        }
    },
    methods: {
        deleteCupo: function () {
            this.deleting = true;
            CupoNumeroService.delete(this.cupoNumero.id)
                .then(() => {
                    this.$ui.notificate({
                        message: 'CUPO ELIMINADO',
                    });
                    this.$emit('deleted', this.cupoNumero);
                })
                .catch(() => {
                    this.$ui.notificate({
                        message: 'Error eliminando el cupo, intente mas tarde o notifique a soporte.',
                        color: 'error'
                    });
                })
                .finally(() => this.deleting = false);
        },
    },
}
</script>